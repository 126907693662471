import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { compose } from "recompose";
import { db, auth, firebase } from "../../firebase";
import * as routes from "constants/routes";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export const LoginGoogle = (props) => {
  const {
    action,
    history,
    item,
    itemTemplate,
    type,
    saveDemo,
    setUserSignupModal,
    deleteUserConfirm,
    changePassword,
    saveEmail,
    close,
    loginRedirect,
  } = props;

  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const setUserName = useCallback(
    (name) => dispatch({ type: "USER_NAME", name }),
    [dispatch]
  );
  const setUserSignup = useCallback(
    (userSignup) => dispatch({ type: "USER_SIGNUP", userSignup }),
    [dispatch]
  );

  const signupUser = (user, action) => {
    setUserSignup(true);
    db.getUser(user.uid).then((result) => {
      if (setUserSignupModal) setUserSignupModal(false);
      if (result.val() === null) {
        db.createUser(
          user.uid,
          user.displayName,
          user.email,
          true,
          user.photoURL
        )
          .then(() => {
            setUserName(user.displayName);
            firebase.sendWelcomeEmail({ email: user.email });
            firebase
              .checkUserInvites({ email: user.email, uid: user.uid })
              .then(() => {
                signupRedirect(user, action);
              });
          })
          .catch((error) => console.log(error));
      } else {
        if (!result.val().profileImage && user.photoURL) {
          db.saveProfileImage(user.uid, user.photoURL).then(() => {
            signupRedirect(user, action);
          });
        } else {
          signupRedirect(user, action);
        }
      }
    });
  };

  const signupRedirect = (user, action) => {
    if (action === "signup" && !item) {
      // TODO: point to a new 'welcome' page where you list out the options
      setUserSignup(false);
      history.push(routes.Styleguides);
    } else if (action === "signup" && item) {
      db.createItem(type, user.uid, saveDemo, itemTemplate.version);
    } else {
      setUserSignup(false);
      loginRedirect(user.email);
    }
  };

  const loginWithGoogle = (event) => {
    event.preventDefault();
    const provider = new GoogleAuthProvider();
    auth
      .doSignInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        if (action === "signup" || action === "login") {
          signupUser(user, action);
        } else {
          if (authUser.uid === user.uid) {
            if (action === "email") {
              saveEmail();
            } else if (action === "account") {
              deleteUserConfirm();
            } else if (action === "password") {
              changePassword();
            }
            close();
          } else {
            db.getUser(user.uid).then((result) => {
              if (result.val() === null) {
                user.delete().then(() => {
                  auth.doSignOut().then(() => {
                    history.push(routes.Login);
                    setTimeout(() => {
                      window.alert(
                        "Sorry, something went wrong.\nThat user account doesn't exist yet."
                      );
                    }, 100);
                  });
                });
              } else {
                auth.doSignOut().then(() => {
                  history.push(routes.Login);
                  setTimeout(() => {
                    window.alert(
                      "Sorry, something went wrong.\nThat email doesn't match your current login."
                    );
                  }, 100);
                });
              }
            });
          }
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="login-google">
      <button className="btn btn-google" onClick={loginWithGoogle}>
        <i className="icon icon-google" />
        Google {action === "signup" ? "sign up" : "log in"}
      </button>
    </div>
  );
};

export default compose(LoginGoogle);
